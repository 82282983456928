<!-- 发布职位显示的数据样式 -->
<template>
  <div class>
    <transition-group name="linear">
      <div v-for="(item, index) in jobList" :key="index">
        <div :class="{ main_content: true }"  >
          <div class="show">
            <p>{{ item.jd_title }} </p>


            <p>
              <span>
                {{ item.place_name }}
                <i></i>
              </span>
              <span>
                {{ item.jd_exp_name }}
                <i></i>
              </span>
              <span>
                {{ item.jd_edu_name }}
                <i></i>
              </span>
              <span>{{ item.jd_salary_min }}-{{ item.jd_salary_max }}元</span>
            </p>
          </div>

          <div class="operation">
            <span @click="jd_refresh(item)">刷新</span>
            <span @click="publishForm(item)">编辑</span>
            <span @click="closeHandle(item)">{{
              item.jd_hide === 1 ? '上架' : '下架'
            }}</span>
            <span @click="detele(item)">删除</span>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapMutations, mapState ,mapActions} from 'vuex' 
import { jd_refresh } from "@/api/tool/index.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    jobList: {
      type: Array,
    },
  },
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    
    jd_refresh(e) {
      
      console.log(e.jd_id );

      jd_refresh({
        jd_id: e.jd_id , 
      }).then((res) => {
        
        if (!res.code) {
          this.$message.success(res.msg);
          this.getJD();
        } else {
          this.$message.error(res.msg);
        }
      });

      this.dialogFormVisible = false;
    },

    ...mapActions({
      getJD: 'tool/getJD',
    }),

    ...mapMutations({
      emitPublishForm: 'message/emitPublishForm',
    }),
    //灰朦罩子
    publishForm(item) {
      this.emitPublishForm({ isPublishForm: true, info: item })
    },
    //关闭
    closeHandle(item) {
      console.log(item)
      this.$emit('closeHandle', item)
    },
    //删除
    detele(item) {
      this.$emit('deteleHandle', item)
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/* //@import url(); 引入公共css类 */

.main_content {
  width: 1100px;
  height: 118px;
  background-color: #fff;
  /* margin-top: 14px; */
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #e9e9e9;
  transition: all 1.6s;
  margin-bottom: 6px;
}

/*  */
.show {
  float: left;
  margin-left: 30px;
  clear: both;
}
.show p {
  font-size: 18px;
  color: #414a60;
}
.show p span {
  color: #8d92a1;
  line-height: 21px;
  font-size: 13px;
  margin-right: 5px;
}
.operation {
  float: right;
  line-height: 118px;
  margin-right: 20px;
}
.operation span {
  margin-right: 20px;
  font-size: 14px;
  color: $main_color;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.linear-enter,
.linear-leave-to {
  opacity: 0;
  transform: translateY(80px);
}

.linear-enter-active,
.linear-leave-active {
  transition: all 0.5s ease;
}

/* 下面的 .v-move 和 .v-leave-active 配合使用， 能够实现列表后续的元素， 渐渐地飘上来的结果 */
.linear-move {
  transition: all 0.5s ease;
}

.linear-leave-active {
  position: absolute;
}
//关闭
.close {
  padding-left: 30px;
}
i {
  padding: 0 7px;
  &::before {
    content: '|';
    line-height: 21px;
  }
}
</style>
