import { require } from './../../utils/http'

//发布
function getPublish(data) {
  return require('post', '/api/kapin_ee/tool/v1/jd_create/', data)
}
//更新
function getUpdate(data) {
  let obj = {
    jd_id: data.id,
    update_data: data.update_data,
  }
  return require('post', '/api/kapin_ee/tool/v1/jd_update/', obj)
}
//显示隐藏
function gettrigger(data) {
  let obj = {
    jd_id: data.jd_id,
    jd_hide: data.jd_hide,
  }
  return require('post', '/api/kapin_ee/tool/v1/jd_show_hide/', obj)
}
//删除
function delApi(data) {
  let obj = {
    jd_id: data.jd_id,
  }
  return require('post', '/api/kapin_ee/tool/v1/jd_delete/', obj)
}
//职位诱惑
function getwelfare() {
  return require('post', '/api/kapin_ee/tool/v1/jd_tags_back/')
}

export { getPublish, getUpdate, gettrigger, getwelfare, delApi }
