<!-- 发布职位 -->
<template>
  <div class="wrap">
    <div class="fixed">
      <el-button class="btn" @click="publishForm">
        <i class="el-icon-plus"></i>
        <span>发布职位</span>
      </el-button>
      <!-- <div class="search_wrap">
        <el-input placeholder="请输入职位名称" disabled>
          <i slot="suffix" class="el-icon-search search" @click="search"></i>
        </el-input>
      </div>-->
    </div>

    <div class="public-style">
      <el-tabs v-model="activeName">
        <el-tab-pane label="上架" name="first">
          <PublishJobComponent
            @deteleHandle="deteleHandler($event)"
            @closeHandle="closeHandler($event)"
            :jobList="show"
          ></PublishJobComponent>
        </el-tab-pane>
        <el-tab-pane label="下架" name="second">
          <PublishJobComponent
            @deteleHandle="deteleHandler($event)"
            @closeHandle="closeHandler($event)"
            :jobList="hide"
          ></PublishJobComponent>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import PublishJobComponent from '@/components/publishJob'
import { mapMutations, mapState, mapActions } from 'vuex'
// import { getPublish } from '@/api/tool/publish'
import { getCompanyShow } from './../../api/company/company'
import { gettrigger, delApi } from '@/api/tool/publish'
import {
  work_time,
  company_scale,
  educationBackground,
} from './../../utils/searchtype'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { PublishJobComponent },
  data() {
    //这里存放数据
    return {
      activeName: 'first',
      // 发布职位数据
      // jobList: [],

      //状态
      command: '全部',
      statusAction: false,
      //上下架
      // hide: [],
      // show: []
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      jobList: (state) => state.tool.jd_data,
      hide: (state) => state.tool.hide,
      show: (state) => state.tool.show,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    search() {
      console.log('11')
    },
    ...mapMutations({
      emitPublishForm: 'message/emitPublishForm',
    }),
    ...mapActions({
      getJD: 'tool/getJD',
    }),
    //灰朦罩子
    publishForm() {
      this.emitPublishForm({ isPublishForm: true })
    },
    //删除
    deteleHandler(e) {
      console.log(e)
      this.$confirm('是否删除该职位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let obj = {
            jd_id: e.id,
          }
          delApi(obj).then((res) => {
            if (res.code === 0) {
              this.getJD()

              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //关闭
    closeHandler(e) {
      console.log(e)
      let obj = {}

      if (e.jd_hide === 1) {
        obj = {
          jd_id: e.id,
          jd_hide: 0,
        }
      } else if (e.jd_hide === 0) {
        obj = {
          jd_id: e.id,
          jd_hide: 1,
        }
      }
      console.log(obj)

      gettrigger(obj).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.getJD()

          this.$message({
            message: res.msg,
            type: 'success',
          })
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    /*
    上架下架两块 
    hide 上架
    show  下架
    */
    this.getJD()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/* //@import url(); 引入公共css类 */

.wrap {
  @include padding;
  min-width: 1100px;
  position: relative;
  padding-top: 54px;
}

.fixed {
  position: fixed;
  top: 61px;
  width: 1100px;
  z-index: 500;
  padding: 30px 0 10px;
  margin: 0 auto;
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  background-color: #f6f8fb;
}

.search_wrap {
  float: right;
}

.search {
  position: relative;
  right: -5px;
  top: 0px;
  background-color: $main_color;
  width: 40px;
  height: 100%;
  color: #fff;
  line-height: 37px;
  border-radius: 0 40px 40px 0;
  font-size: 16px;
  vertical-align: middle;
  cursor: pointer;
}
div /deep/ .el-input {
  width: 200px;
}
div /deep/ .el-input__inner {
  width: 200px;
  height: 34px;
  border-radius: 40px;
}
.status {
  margin-left: 50px;
  cursor: pointer;
}
.action {
  color: $main_color;
}
/* .status:hover span {
  color: #00cab3;
} */
.public-style {
  margin-top: 20px;
}
//el tab
div /deep/ .el-tabs__active-bar {
  background-color: $main_color;
}
div /deep/ .el-tabs__item:hover {
  color: $main_color;
}
div /deep/ .el-tabs__item.is-active {
  color: $main_color;
}
.btn {
  padding: 12px 26px 12px 12px;
}
</style>
