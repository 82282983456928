import { require } from './../../utils/http'
//生成二维码

function getCode(data) {
  return require('post', '/api/kapin_ee/tool/v1/produce_qr/', data)
}
//获取简历管理数据
function getResumeManage(data) {
  let obj = {
    page: data.page,
    is_online: data.is_online,
    status: data.status,
    keyword: data.keyword,
  }
  return require('post', '/api/kapin_ee/tool/v1/resume_management/', obj)
}
//简历详情
function getResumeDetails(data) {
  let obj = {
    resume_id: data,
  }
  return require('post', '/api/kapin_ee/tool/v1/resume_management_detail/', obj)
}
//简历详情 简历备注
function getResumeRemark(data) {
  let obj = {
    resume_id: data.resume_id,
    remarks: data.remarks,
  }
  return require('post', '/api/kapin_ee/tool/v1/resume_management_remarks/', obj)
}
//简历详情 简历来源
function getResumeSource(data) {
  let obj = {
    resume_id: data.resume_id,
    source_net: data.source_net,
  }
  return require('post', '/api/kapin_ee/tool/v1/resume_management_source/', obj)
}
//简历详情 简历状态
function getResumeStatus(data) {
  let obj = {
    resume_id: data.resume_id,
    status: data.status,
  }
  return require('post', '/api/kapin_ee/tool/v1/resume_management_status/', obj)
}
//echarts 数据汇总
function getEchartsA() {
  return require('post', '/api/kapin_ee/tool/v1/online_resume/')
}
function getEchartsB() {
  return require('post', '/api/kapin_ee/tool/v1/liechang_resume/')
}
//简历雷达
function getRadar(data) {
  return require('post', '/api/kapin_ee/tool/v1/radar_list/', data)
}
//简历雷达沟通备注
function changeRadarRemarks(data) {
  return require('post', '/api/kapin_ee/tool/v1/radar_remarks/', data)
}
//简历雷达修改状态
function changeRadarStatus(data) {
  return require('post', '/api/kapin_ee/tool/v1/radar_status/', data)
}

//excal上传简历
function upload_file(data) {
  return require('post', '/api/kapin_ee/tool/v1/excel_create_resume/', data)
}

//特殊简历雷达
function ts_radar_list(data) {
  return require('post', '/api/kapin_ee/tool/v1/ts_radar_list/', data)
}

//特殊监视手机号
function ts_radar_mobile(data) {
  return require('post', '/api/kapin_ee/tool/v1/ts_radar_mobile/', data)
}
//删掉特殊监视手机号
function ts_radar_mobile_delete(data) {
  return require('post', '/api/kapin_ee/tool/v1/ts_radar_mobile_delete/', data)
}

//简历详情 简历备注
function set_cdatetime(data) {

  return require('post', '/api/kapin_ee/tool/v1/set_cdatetime/', data)
}
//返回该企业的面试管理信息
function get_audition(data) {

  return require('post', '/api/kapin_ee/tool/v1/get_audition/', data)
}
//删除面试管理信息
function get_audition_del(data) {

  return require('post', '/api/kapin_ee/tool/v1/get_audition_del/', data)
}

//删除面试管理信息
function jd_refresh(data) {

  return require('post', '/api/kapin_ee/tool/v1/jd_refresh/', data)
}



export {
  getCode,
  getResumeManage,
  getEchartsA,
  getEchartsB,
  getResumeDetails,
  getResumeRemark,
  getResumeSource,
  getResumeStatus,
  getRadar,
  changeRadarRemarks,
  changeRadarStatus,
  upload_file,
  ts_radar_list,
  ts_radar_mobile,
  ts_radar_mobile_delete,
  set_cdatetime,
  get_audition,
  get_audition_del,
  jd_refresh,
}
